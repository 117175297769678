<template>
    <div class="page">
        <router-view></router-view>
    </div>
</template>

<script>
import { eventHub } from "@/main";

export default {
    name: "PublicLayout",

    computed: {
        isSmallDevice() {
            return ["xs", "sm"].indexOf(this.$vuetify.breakpoint.name) >= 0;
        },
    },

    data() {
        return {
            overlay: false,
            logoUrl: "/img/logo.png",
            menuItems: [
            ],
        };
    },

    created() {
        // this.initKeycloak();

        console.log('query ', this.$route)

        eventHub.$on("THEME_UPDATED", (config) => {
            this.logoUrl = config.logoUrl;
        });
    },

    methods: {

        // async initKeycloak() {
        //     console.log('init keycloak');
        //     let initOptions = {
        //         url: 'https://login.lavepay.com', realm: 'lavepay-bo', clientId: 'web_app',
        //         onLoad: 'login-required'
        //         // onLoad: 'check-sso',
        //         // redirectUri: "http://localhost:8080/*"
        //     }

        //     let keycloak = Keycloak(initOptions);

        //     console.log('keycloak ', keycloak)

        //     keycloak.init({ onLoad: initOptions.onLoad }).success((auth) => {

        //         console.log('auth', auth)

        //         if (!auth) {
        //             console.log('not success ', auth)
        //             // window.location.reload();
        //         } else {
        //             console.log('success', auth)
        //             // Vue.$log.info("Authenticated");
        //         }

        //         // new Vue({
        //         //   render: h => h(App),
        //         // }).$mount('#app')


        //         localStorage.setItem("vue-token", keycloak.token);
        //         localStorage.setItem("vue-refresh-token", keycloak.refreshToken);

        //         // setInterval(() => {
        //         //   keycloak.updateToken(70).success((refreshed) => {
        //         //     if (refreshed) {
        //         //       // Vue.$log.debug('Token refreshed' + refreshed);
        //         //       console.log('keycloack refresh')
        //         //     } else {
        //         //       // Vue.$log.warn('Token not refreshed, valid for '
        //         //       //   + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
        //         //     }
        //         //   }).error(() => {
        //         //     // Vue.$log.error('Failed to refresh token');
        //         //   });


        //         // }, 60000)

        //     }).error((err) => {
        //         // Vue.$log.error("Authenticated Failed");
        //         console.log('error auth', err)
        //     });
        // },

    },
};
</script>
<style lang="scss" scoped>
.page {
    height: 100%;
    //   background-color: rgba(0, 0, 0, 0.5);
}

// .v-app-bar {
// background: linear-gradient(
//   180deg,
//   var(--v-primary-base) 35.08%,
//   rgba(0, 0, 0, 0) 100%
// );
// background-blend-mode: multiply;
// }
.menu-link {
    font-weight: 700;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    margin-right: 30px;

    &:link {
        text-decoration: none;
    }

    &:hover {
        text-decoration: underline;
    }
}

.div-background {
    width: 60%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--v-primary-base);
    // background-color: #222D6C;
    transform: scale(1.1);
}

.div-router {
    width: 40%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
</style>